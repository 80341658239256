import React, { useEffect, useState } from 'react'
import QuizCharater from '../images/quizCharacter.png';
import BackBtn from '../images/back_arrow.png'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios'
import Loader from '../images/loader.gif'

export default function QuizPrompt() {
    const { content_id, course_id, section_id } = useParams();
    const [correct, setCorrect] = useState(0);
    const [mistakes, setMistakes] = useState(0);
    const [attempts, setAttempts] = useState(0);
    const [per, setPer] = useState(0);
    const [allow, setAllow] = useState('none');
    const [loaderDisplay, setLoaderDisplay] = useState("none");

    useEffect(()=>{

        (async () => { 
            setLoaderDisplay("block");
           var url = `https://api.mudaris.org/api/checkAttempt`;
         
            const response = await axios.post(url,{user_id:sessionStorage.getItem('id'),content_id:content_id}, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
  
            }).then((res=>{
             
                setLoaderDisplay("none");
             if(!res.data){
                setAttempts(0);
                setPer(0);

                setAllow('block')
             }else{
              setAttempts(res.data.count);
              setCorrect(res.data.attempts.correct);
              setMistakes(res.data.attempts.false);
              var p = ((res.data.attempts.correct/(res.data.attempts.correct + res.data.attempts.false))*100);
              setPer(p)
                if(p < 80){
                    setAllow('block')
                }
                
             }
             
             
            })).catch(error => {
              console.log(error)
            });
          })();  

    },[]);
   
   
  return (
    <>
        <div id='backbtn' className='my-3'>
           <Link to={`/courseDetail/${course_id}`}> <img src={BackBtn} alt='back btn' style={{width:"80px", height:"40px"}}/></Link>
        </div>
    <div className='my-3' >
            <div className="card text-center ">
            <div className="card-header ">
               Quiz
            </div>
            <div className="card-body">
                <div className='row'>

                    <div className='col-md-6'>

                        <img src={QuizCharater} style={{width:"200px", height:"300px"}}/>

                    </div>
                    <div className='col-md-6'>
                        <div style={{marginTop:"20%"}}></div>
                        <h3 style={{marginTop:"2%"}}>Previous Attempt {attempts}</h3>
                        <h3 style={{marginTop:"2%"}}>Correct {correct}</h3>
                        <h3 style={{marginTop:"2%"}}>Mistakes {mistakes}</h3>
                        <h3 style={{marginTop:"2%"}}>Percentage {per}%</h3>

                    </div>

                </div>
            </div>
            <div className="card-footer text-muted">
               <Link to={`/Quiz/${content_id}/${course_id}/${section_id}`} style={{display:allow}}><button className='btn btn-danger'>Start Quiz</button></Link>
            </div>
            </div>

            <div style={{position:"fixed", top:"20%", left:"45%",display:loaderDisplay}} >
                <img  src={Loader} style={{width:"200px", height:"200px"}}/>
            </div>
        
    </div>
    </>
  )
}
