import './App.css';
import LeftNav from './components/leftNav';
import MiddleComp from './components/middleComp';
import RightComp from './components/rightNav';
import { Outlet } from "react-router-dom";
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function App() {

  const navigate = useNavigate();

    useEffect(()=>{
      console.log(sessionStorage.getItem('id'));
      if(!sessionStorage.getItem('id')){
        navigate('/login');
      }

      if(sessionStorage.getItem("subscription") == "0"){
        navigate('/Subscription');
      }
      
    });

  return (
    <>
        
        <div class="row " >
          <div class="col-md-3">
            <LeftNav />
          </div>
          <div class="col-md-6">
          <div id="detail">
            <Outlet />
          </div>
          </div>
          <div class="col-md-3">
           <RightComp />
          </div>
        </div>
     
    </>
  );

  
}

export default App;
