import React from 'react'
import Login from './login'
import CourseList from './courseList'
import CourseDetail from './courseDetail'
import Lecture from './lecture'
import LeaderBoard from './leaderboard'
import Profile from './profile'
export default function middleComp() {
  return (
    <div>
      {/* <Login /> */}
    {/* <CourseList /> */}
    <CourseDetail />
    {/* <Lecture /> */}
    {/* <LeaderBoard /> */}
    {/* <Profile/> */}
    </div>
  )
}
