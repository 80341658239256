import React from 'react'
import Learn from '../images/learn.jpg';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from "axios";
import Loader from "../images/loader.gif"

export default function Tutorials() {

    const [courses, setCourses] = useState([]);
    const [loaderDisplay, setLoaderDisplay] = useState("none");
    const [searchText, setSearchText] = useState("");

    useEffect(()=>{
        const bearer_token = `Bearer ${sessionStorage.getItem("token")}`;
       
            (async () => { 
              setLoaderDisplay("block");
              const response = await axios.get(`https://api.mudaris.org/api/getLecturesTutorial`, {
                headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },

              }).then((res=>{

                setCourses(res.data.data);
               setLoaderDisplay("none ");
              })).catch(error => {
                console.log(error)
              });
            })();
    },[]);
   

    function handleSearch(event){
            setSearchText(event.target.value)
    }

    function handleSearchBtn(){

        const bearer_token = `Bearer ${sessionStorage.getItem("token")}`;
        var url = `https://api.mudaris.org/api/searchTutorials`;
       
        (async () => { 
          setLoaderDisplay("block");
          const response = await axios.post(url,{search:searchText}, {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },

          }).then((res=>{

            setCourses(res.data);
           setLoaderDisplay("none ");
          })).catch(error => {
            console.log(error)
          });
        })();

    }

  return (
    <div>
       
        <div id='courseItem'>
        
            <div className='row' style={{marginTop:"60px"}}>
                <div className='col-md-8'>
                <input type='text' placeholder='What you are looking for ' className='form-control' onKeyUp={handleSearch}/>
                </div>
                <div className='col-md-4'>
                    <button className='btn btn-danger' onClick={handleSearchBtn}>Search</button>
                </div>
            </div>

            {courses.map((data)=>{
                return (
                    <>
                       <Link to={`/lecture/${data.id}/0/0/0/tutorial`}>
                        <div className='row my-5 courseList'  >
                       
                        <div className='col-md-8'> 
                            <h5>{data.name}</h5>
                            <hr/>
                        </div>
                        </div>
                        </Link>
                    </>
                )
            })


            }
                      
        </div>

        <div style={{position:"fixed", top:"20%", left:"45%",display:loaderDisplay}} >
          <img  src={Loader} style={{width:"200px", height:"200px"}}/>
        </div>
       
    </div>
  )
}