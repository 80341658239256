import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Character1 from '../images/character1.png';
import axios from 'axios'
import Loader from '../images/loader.gif'
import { useNavigate } from 'react-router-dom';

export default function CompletionScreen() {
    const { correct, mistakes , course_id, content_id, section_id } = useParams();
    var [per, setPer] = useState(0);
    var [coins, setCoins] = useState(0);
    const [loaderDisplay, setLoaderDisplay] = useState("none");
    const navigate = useNavigate();

    useEffect(()=>{
            var total = +correct + +mistakes;
            var percent = (correct / total)*100;
            console.log(total);
            setPer((correct / total)*100);

            if(percent >= 80){
                setCoins(20);
            }

            

    },[]);


    function handleContinue(){
      if(coins > 0){
        (async () => { 
          setLoaderDisplay("block");
         var url = `https://api.mudaris.org/api/saveCoin`;
       
          const response = await axios.post(url,{user_id:sessionStorage.getItem('id'),coin :coins}, {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },

          }).then((res=>{
           
           
          
          })).catch(error => {
            console.log(error)
          });
        })();  
      }

      if(per >= 80 ){

        (async () => { 
            
          var url = `https://api.mudaris.org/api/markAsCompleted`;
        
           const response = await axios.post(url,{content_id:content_id,course_id:course_id,section_id:section_id}, {
             headers:{ Authorization: `Bearer ${sessionStorage.getItem('token')}` },
 
           }).then((res=>{
             
            

            setLoaderDisplay("none");
            navigate('/courseDetail/'+course_id);
             
           })).catch(error => {
             console.warn(error)
             
           });
         })();  


      }else{
        setLoaderDisplay("none");
        navigate('/courseDetail/'+course_id);
      }
        
    }



  return (
    <div className='container'>
        

        <div className='row'>

            <div className='col-md-4'>
            <img src={Character1} style={{width:"200px", height:"450px"}}/>
            </div>
            <div className='col-md-8'>

                    <table width="100%" style={{marginTop:"10%"}}> 
                        <tr>
                            <th>Correct</th>
                            <th>Mistakes</th>
                            <th>Percentage</th>
                            <th>Coins Earned</th>
                        </tr>
                        <tr>
                            <td><h1 style={{color:"green"}}>{correct}</h1></td>
                            <td><h1 style={{color:"red"}}>{mistakes}</h1></td>
                            <td><h1>{per}%</h1></td>
                            <td><h1 style={{color:"orange"}}>{coins}</h1></td>
                        </tr>
                    </table>

                  

            
            </div>

        </div>

        <div style={{marginTop:"5%"}}>
            <center><button className='mudaris_btn_quiz' onClick={handleContinue}>Save and Continue to Course</button></center>
        </div>


        <div style={{position:"fixed", top:"20%", left:"45%",display:loaderDisplay}} >
          <img  src={Loader} style={{width:"200px", height:"200px"}}/>
        </div>

    </div>
  )
}
