import React from 'react'
import { useNavigate } from 'react-router-dom';
import Character1 from '../images/character1.png'
import { useEffect, useState } from 'react';
import axios from 'axios';



export default function Login() {

  const [users, setUsers] = useState([]);
  const [email,setEmail] = useState('');
  const [btnText, setBtnText] = useState("Login");
  const [password,setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();


  function handleEmail(event){
      setEmail(event.target.value);
  }

  function handleOtp(event){
    setOtp(event.target.value);
  }

  function handlePassword(event){
    setPassword(event.target.value);
}
  const moveToHome = async (e) => {

    setBtnText("Please Wait.....");
      axios
        .post("https://api.mudaris.org/api/OTPLogin",{
          'email':email,
          'password':password,
          'otp':otp,
        })
        .then((res) => {

          setUsers(res.data)
          setBtnText("Login")
          console.log(res.data);
          sessionStorage.setItem("id",res.data.user.id)
          sessionStorage.setItem("name",res.data.user.name)
          sessionStorage.setItem("email",res.data.user.email)
          sessionStorage.setItem("mobile",res.data.user.mobile_number)
          sessionStorage.setItem("token",res.data.access_token)
          sessionStorage.setItem("subscription",res.data.user.subscription_status)
          navigate('/courses')
  

        })
        .catch(err => {
          
          alert(err.response.data.error);
          setBtnText("Login")
       });
  
  
  }


  return (
    


      <div className='row'>

          <div className='col-md-6' style={{backgroundColor:"red", height:"100vh"}}>

            <div className='row'>

                  <div className='col-md-4'>
                  <img src={Character1} style={{width:"200px", height:"450px", position:"absolute",bottom:"0"}}/>
                  </div>
                  <div className='col-md-6'>
                  <div  style={{marginTop:"80%",color:"white",fontWeight:"bold", width:"100%"}}><h1 >Welcome!</h1><h2>Happy new Journey with Mudaris</h2></div>
                  </div>

            </div>
            
            

          </div>
          <div className='col-md-6'>

          <h3 className='my-5' style={{width:"50%", margin:"auto"}}>Login Form</h3>

          <div id='loginForm' style={{width:"80%", margin:"auto", marginTop:"50px"}}>

              <label>Email</label>
              <input type='email' className='form-control my-3' id='email' onKeyUp={handleEmail}/>
              <label>Password</label>
              <input type='password' className='form-control my-3' id='otp' onChange={handlePassword}/>
              <label>OTP (Get From Mudaris Application)</label>
              <input type='password' className='form-control my-3' id='otp' onChange={handleOtp}/>
              <button className='mudaris_btn' style={{marginLeft:"30%"}} onClick={moveToHome}>{btnText}</button>
          </div>


          </div>

      </div>
      
       

  )
}
