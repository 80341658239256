import React from 'react'
import BackBtn from '../images/back_arrow.png'
import Logo from '../logo.svg'


export default function courseDetail() {

    

  return (

    <div className='my-3'>
        <div id='backbtn'>
            <img src={BackBtn} alt='back btn' style={{width:"80px", height:"40px"}}/>
        </div>
        <div className='my-3'>

            <div id='profile'>

            <div className='row '>
                        
                        <div className='col-md-3'>
                            <img src={Logo}/>
                        </div>
                        <div className='col-md-6 my-3' >
                            <h3>{sessionStorage.getItem("name")}</h3>
                            <p >Joined: 20/3/2024</p>
                            <p>Email: {sessionStorage.getItem("email")}</p>
                        </div>

                    </div>

            </div>


        </div>

    </div>
  )
}
