import React, { useEffect, useState } from 'react'
import Logo from '../logo.svg'
import Profile from '../images/profile.png'
import axios from 'axios'
    

export default function LeaderBoard() {

    const [board,setBoard] = useState([]);


    useEffect(()=>{


        (async () => { 
            //setLoaderDisplay("block");
           var url = `https://api.mudaris.org/api/leaderBoard`;
         
            const response = await axios.post(url,{}, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
  
            }).then((res=>{
                console.log(res.data);
                setBoard(res.data);
             
            
            })).catch(error => {
              console.log(error)
            });
          })();  

    },[]);
    

  return (

    <div className='my-3'>
       
        <div className='my-5'>

            <div id='leaderBoard'>


                {board.map((data)=>{
                        return (

                            <div className='row list'>
                            
                            <div className='col-md-3'>
                                <img src={Profile} width="80px" height="80px"/>
                            </div>
                            <div className='col-md-6 my-3' >
                                <h3>{data.name}</h3>
                                <span className='label'>Coins {data.coin}</span>
                            </div>
    
                        </div>
                        )
                })}
            
                    

                

                  

            </div>


        </div>

    </div>
  )
}
