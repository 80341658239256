import React from 'react'
import { Link, useParams } from 'react-router-dom'
import Quiz_1 from '../images/quiz_1.png'
import Quiz_2 from '../images/quiz_2.png'
import Quiz_3 from '../images/quiz_3.png'
import { useEffect, useState } from 'react'
import Loader from '../images/loader.gif'
import { useNavigate } from 'react-router-dom';
import axios from 'axios'

export default function Quiz() {
    const {id,course_id,section_id} = useParams();
    const [quizzes, setQuizzes] = useState([]);
    const [loaderDisplay, setLoaderDisplay] = useState("none");
    var  [currentIndex, setCurrentIndex] = useState(0);
    const [currentQuestion, setCurrentQuestion] = useState("");
    const [currentOptions, setCurrentOptions] = useState([]);
    const [correctDisplay, setCorrectDisplay] = useState('none');
    const [wrongDisplay, setWrongDisplay] = useState('none');
    const [quizCharacter, setQuizCharacter] = useState(Quiz_1);
    const [progressValue, setProgressValue] = useState(0);
    const [quizClick, setQuizClick] = useState(0);
    var [score, setScore] = useState(0);
    var [mistakes, setMistakes] = useState(0);
    const navigate = useNavigate();

    useEffect(()=>{


        (async () => { 
            setLoaderDisplay("block");
           var url = `https://api.mudaris.org/api/getQuiz`;
         
            const response = await axios.post(url,{content_id:id}, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },

            }).then((res=>{
              setQuizzes(res.data.quiz);
              setLoaderDisplay("none");
              
              setCurrentQuestion(res.data.quiz[currentIndex].question)
              setCurrentOptions(res.data.quiz[currentIndex].option)
              console.log(currentOptions);
            })).catch(error => {
              console.log(error)
            });
          })();  
          
       

    },[]);


    function handleResponse(e){


        if(quizClick == 0){
            var res_id = e.target.getAttribute('res_id');

            var option = currentOptions[res_id];
    
            //save answer to database

            (async () => { 
                setLoaderDisplay("block");
               var url = `https://api.mudaris.org/api/saveAnswer`;
             
                const response = await axios.post(url,{option_id:option.id,correct:option.correct,quiz_id:id,user_id:sessionStorage.getItem("id")}, {
                  headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
    
                }).then((res=>{
                  
                  setLoaderDisplay("none");

                  if(option.correct == 1){
                    setCorrectDisplay("block");
                    setWrongDisplay("none");
                    setQuizCharacter(Quiz_2);
                   
                   setScore(++score);
               
               }else{
                    setCorrectDisplay("none");
                    setWrongDisplay("block");
                    setQuizCharacter(Quiz_3)
                    setMistakes(++mistakes);
               }
               setQuizClick(1)
                  
                 
                })).catch(error => {
                  console.log(error)
                });
              })();  


        }
        

    }

    function handleNextQuestion(e){
        var type = e.target.getAttribute('ty');
        var totalQuestions = quizzes.length;
        setCurrentIndex(++currentIndex)

        var progress_1 = (currentIndex / totalQuestions) * 100;
        console.log(progress_1);
        setProgressValue(progress_1);
        setQuizCharacter(Quiz_1);
        if(currentIndex < totalQuestions){
            setQuizClick(0);
            console.log(currentIndex);
            setCurrentQuestion(quizzes[currentIndex].question)
            setCurrentOptions(quizzes[currentIndex].option)
            setCorrectDisplay("none");
            setWrongDisplay("none");

        }else{
            
            (async () => { 
                setLoaderDisplay("block");
               var url = `https://api.mudaris.org/api/saveAttempt`;
             
                const response = await axios.post(url,{time:0,correct:score,false:mistakes,content_id:id,user_id:sessionStorage.getItem("id")}, {
                  headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
    
                }).then((res=>{
                  
                  setLoaderDisplay("none");
                  navigate('/CompletionScreen/'+score+'/'+mistakes+'/'+course_id+'/'+id+'/'+section_id);
                 
                })).catch(error => {
                  console.log(error)
                });
              })();  

            setCorrectDisplay("none");
            setWrongDisplay("none");
        }
        
    }


  return (
    <div>

        <div className='container'>

            <div className='row' style={{marginTop:"3%"}}>
                <div className='col-md-12'>
                    <div class="progress">
                        <div class="progress-bar" role="progressbar" style={{width:progressValue+"%",backgroundColor:"red"}} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </div>
               
            </div>


            <div className='Question_area'>
                <div className='row'>
                    <div className='col-md-4'>
                        <img src={quizCharacter}  style={{width:"250px",height:"400px"}}/>
                    </div>
                    <div className='col-md-8'>
                        <div className='questionArea'>     
                            <h1>{currentQuestion.question}</h1>
                            <div className='btn_area'>
                               
                               {currentOptions.map((data,key)=>{

                                    return (
                                        <>
                                        <button className='mudaris_btn_quiz'  res_id={key} onClick={handleResponse}>{data.option}</button><br/>
                                        </>
                                    );

                               })}
                                
                               
                            </div>
                        </div>
                   
                    </div>
                </div>
            </div>

           

        
        </div>


        <div className='QPrompt' style={{backgroundColor:"#80ff80", border:"2px solid #009900", display:correctDisplay}}>

            <h2 style={{margin:"20px"}}>Correct Answer! Good Job</h2>
            <button className='mudaris_btn' style={{float:"right"}} ty='1' onClick={handleNextQuestion}>Continue</button>
        </div>

        <div className='QPrompt' style={{backgroundColor:"#ff8080", border:"2px solid #cc0000", display:wrongDisplay}}>

            <h2 style={{margin:"20px"}}>Oops! Your Answer is Wrong</h2>
            <button className='mudaris_btn' style={{float:"right"}} ty='0' onClick={handleNextQuestion}>Continue</button>
        </div>

        <div style={{position:"fixed", top:"20%", left:"45%",display:loaderDisplay}} >
          <img  src={Loader} style={{width:"200px", height:"200px"}}/>
        </div>

    </div>
  )
}
