import React, { useEffect, useState } from 'react'
import BackBtn from '../images/back_arrow.png'
import LectureVideo from '../videos/Lecture 01.mp4'
import { Link, useFetcher, useParams, useNavigate  } from 'react-router-dom'
import axios from 'axios'
import Loader from '../images/loader.gif'



export default function Lecture(props) {

    const {id,course_id,section_id,completeStatus,type} = useParams();
    const [lectures, setLectures] = useState([]);
    const [content, setContent] = useState('');
    const [loaderDisplay, setLoaderDisplay] = useState("none");
    const [currentLink, setCurrentLink] = useState("/");
    const [commentInput, setCommentInput] = useState('');
    const [comments, setComments] = useState([]);
    const [replyDialog, setReplyDialog] = useState("none")
    const [commentId, setCommentId] = useState(0) 
    const [replyText, setReplyText] = useState("")
    const user_id = sessionStorage.getItem('id');
    const [warninghere, setWarningHere] = useState("none");
    const navigate = useNavigate();


   

   

    useEffect(()=>{
      if(completeStatus == "null" && type === "course"){
        setWarningHere("block")
      }
        (async () => { 
            setLoaderDisplay("block");
           var url = `https://api.mudaris.org/api/getContent/`+id;
         
            const response = await axios.get(url, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },

            }).then((res=>{
              console.log(res)
              //setLectures(res.data.lectures);
              //setContent(res.data.content);
              setLoaderDisplay("none");
              console.log(lectures);
             if(res.data.lectures){
                setCurrentLink(res.data.lectures[0].link)
             }
            })).catch(error => {
              console.log(error)
            });
          })();         

    },[]);


    useEffect(()=>{

        (async () => { 
            setLoaderDisplay("block");
           var url = `https://api.mudaris.org/api/getComments/`+id;
         
            const response = await axios.get(url, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },

            }).then((res=>{
             setComments(res.data.data);
              setLoaderDisplay("none");
            
            })).catch(error => {
              console.log(error)
            });
          })();  
          
          console.log(comments);

    },[]);

    function handleLinkButton(e){
      setCurrentLink(e.target.getAttribute('li'));
    }

    function handleCommentBtn(e) {

        if(commentInput != ""){
            e.target.setAttribute('disabled',"disabled");
            (async () => { 
                setLoaderDisplay("block");
               var url = `https://api.mudaris.org/api/postComment`;
             
                const response = await axios.post(url,{content_id:id,comment:commentInput}, {
                  headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
    
                }).then((res=>{
                  setComments(res.data.data);
                  setLoaderDisplay("none");
                  setCommentInput('');
                  e.target.removeAttribute('disabled')
                })).catch(error => {
                  console.log(error)
                });
              })();         
        }
        
    
    }

    function handleCommentInput(event){
            setCommentInput(event.target.value)
    }

    function deleteComment(comment_id){
     
      (async () => { 
        setLoaderDisplay('block')
         var url = `https://api.mudaris.org/api/deleteComment`;
       
          const response = await axios.post(url,{content_id:id,comment_id:comment_id},{
            headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },}).then((res=>{
          
            setComments(res.data.data);
            setLoaderDisplay('none')
            
          })).catch(error => {

            setLoaderDisplay('none') 
          });
        })();   

    }

    function replyComment(comment_id){
        setCommentId(comment_id);
        setReplyDialog("block")
    }

    function handleCancelDialog(){
      setReplyDialog("none")
    }

    function handleReply(event){
        setReplyText(event.target.value)
    }

    function postReply(){
      if(replyText != ''){
        
       
        (async () => { 
          setLoaderDisplay('block')
           var url = `https://api.mudaris.org/api/commentReply`;
         
            const response = await axios.post(url,{content_id:id,reply:replyText, comment_id:commentId}, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },}).then((res=>{
            
              setComments(res.data.data);
              setLoaderDisplay("none")
              setReplyText('')
              setReplyDialog("none")
             
              
            })).catch(error => {
              setLoaderDisplay("none")
              setReplyText('')
              setReplyDialog("none")
            });
          })();   
          
        }else{
         alert("Enter valid Reply Text")
        }
    }

    function DeleteReply(repid){
      (async () => { 
        setLoaderDisplay('block')
          var url = `https://api.mudaris.org/api/deleteReplies`;
        
           const response = await axios.post(url,{content_id:id,id:repid},{
            headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },}).then((res=>{
           
             setComments(res.data.data);
            
             setLoaderDisplay('none')
           })).catch(error => {
            console.warn(error)
             setLoaderDisplay('none')
           });
         })();   
    }


    function videoEndingHandler(){

      if(completeStatus == "null" && type == "course"){
        (async () => { 
          setLoaderDisplay('block')
           var url = `https://api.mudaris.org/api/markAsCompleted`;
         
            const response = await axios.post(url,{content_id:id,course_id:course_id,section_id:section_id}, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
  
            }).then((res=>{
             alert("Lecture marked as Completed")
              setLoaderDisplay('none')
              
            })).catch(error => {
              console.warn(error)
              setLoaderDisplay('none')
            });
          })();
      }

        
    }

  return (

    <div className='my-3'>
        <div id='backbtn'>
            <a href='#' onClick={() => navigate(-1)} ><img src={BackBtn} alt='back btn' style={{width:"80px", height:"40px"}}/></a>
        </div>
      
        

            <div className='alert alert-info' style={{display:warninghere}}>Do not Close the until mareked as completed</div>

        
       
      

        <div className='my-3'>
            <div id='videoPlayer'>

                
        <video  controls src={currentLink} style={{width:"100%", height:"300px"}} onEnded={videoEndingHandler}></video>
                
                

            </div>


            <h3 className='my-3'>{content.name}</h3>

            
            <div className='my-3'>

                <p>Watch lecture in</p>

                {lectures.map((data)=>{
                    return(
                        <>
                        <button className='btn btn-warning' style={{marginRight:"3px"}} li={data.link} onClick={handleLinkButton} >{data.language}</button>
                        </>
                    )

                })}


                </div>

            <div id='Comment Section' className='my-3'>

                <textarea placeholder='Enter Your Comment here' onChange={handleCommentInput} value={commentInput} className='form-control'></textarea>
                <button className='mudaris_btn my-3' style={{float:"right"}} onClick={handleCommentBtn}>Post Comment</button>
            </div>


            <div id='commentDisplay' className='my-5'>

                {comments.map((data)=>{

                    return(

                        <>
                            <div id='ind_comment'>
                                <h6>{data.comments.name}</h6>
                                <p>{data.comments.comment}</p>
                                {user_id == data.comments.user_id ? 
                                <a href='#'><span style={{fontSize:10}} onClick={()=>{deleteComment(data.comments.id)}} >Delete</span></a> 
                                  :
                                  null
                                 }
                                 <a href='#'><span style={{fontSize:10, marginLeft:5}} onClick={()=>{replyComment(data.comments.id)}}>Reply</span></a>
                                <hr />
                            </div>
                        


                          {data.replies.map((rep)=>{

                            return(

                                <>
                                    <div id='ind_comment' style={{marginLeft:"25px"}}>
                                        <h6 style={{fontSize:14}}>{rep.name}</h6>
                                        <p style={{fontSize:14}}>{rep.reply}</p>
                                        {user_id == rep.user_id ? 
                                        <a href='#'><span style={{fontSize:10}} onClick={()=>{DeleteReply(rep.id)}} >Delete</span></a> 
                                        :
                                        null
                                       }
                                        <hr />
                                    </div>
                                </>

                            
                            )


                          })}


                    <div style={{width:"50%", height:"auto",display:replyDialog, backgroundColor:"#FDE7E7",border: "2px solid rgb(243, 120, 120)",borderRadius:10, position:"fixed", bottom:10, margin:"auto", padding:"10px"}}>
                          <textarea placeholder='Enter your Reply' className='form-control'onKeyUp={handleReply} >{replyText}</textarea>
                          <br/>
                          <button className='btn btn-danger' style={{float:"right"}} onClick={postReply}>Post</button>
                          <button className='btn btn-warning' style={{float:"right", marginRight:"5px"}} onClick={handleCancelDialog}>Cancel</button>
                          
                    </div>

                    </>
                    )


                })}

                
               
            </div>

        </div>


        <div style={{position:"fixed", top:"20%", left:"45%",display:loaderDisplay}} >
          <img  src={Loader} style={{width:"200px", height:"200px"}}/>
        </div>

    </div>
  )
}
