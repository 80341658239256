import React from 'react'
import MudarisLogo from '../images/mudaris-logo.png';
import Course from '../images/courses.png';
import Leader from '../images/leaderboard.png';
import Tutorials from '../images/tutorials.png'

import Youtube from '../images/youtube.PNG';
import Blog from '../images/blog.PNG';
import Logout from '../images/logout.PNG';
import {  Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';


export default function LeftNav() {

  const navigate = useNavigate();


  function handleLogin(){
    sessionStorage.clear()
    navigate("/login");
  }

  return (
    <div >
      <div id='LogoPart' className='my-3'>
        <img src={MudarisLogo} style={{width:"60px", height:"30px", marginLeft:"30%"}} alt='logo'/>
      </div>
      <hr/>

      <div id='sideBar'>

          <ul>
            <li><Link to='/courses'><img src={Course} alt='courses' style={{width:"25px",height:"25px"}}/> Courses</Link></li>
            {/* <li><Link to='/leaderboard'><img src={Leader} alt='courses' style={{width:"25px",height:"25px"}}/> Leaderboard</Link></li> */}
            {/* <li><Link to='/profile'><img src={Profile} alt='courses' style={{width:"25px",height:"25px"}}/> Profile</Link></li> */}
            <li><Link to='/tutorials' ><img src={Tutorials} alt='courses' style={{width:"25px",height:"25px"}}/> Tutorials</Link></li>
            <li><a href='https://www.youtube.com/channel/UCuTA8pDCmiXtEmEQ307hVXQ' target='_blank'><img src={Youtube} alt='courses' style={{width:"25px",height:"25px"}}/> Youtube</a></li>
            <li><a href='https://mudaris.org/blog/' target='_blank'><img src={Blog} alt='courses' style={{width:"25px",height:"25px"}}/> Blog</a></li>
            {/* <li><Link to='/'><img src={Blog} alt='courses' style={{width:"25px",height:"25px"}}/> Forum</Link></li> */}
            {/* <li><Link to='/'><img src={Blog} alt='courses' style={{width:"25px",height:"25px"}}/> Referal</Link></li> */}
            <li><Link onClick={handleLogin}><img src={Logout} alt='courses' style={{width:"25px",height:"25px"}}/> Logout</Link></li>
          </ul>

      </div>

    </div>
  )
}

