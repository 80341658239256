import React from 'react'
import Coin from '../images/coins.png'
import { useEffect, useState } from 'react'
// import Streak from '../images/streak.png'
// import Heart from '../images/heart.png'
import axios from 'axios'

export default function RightNav() {
  const [coins,setCoints] = useState(0);

  useEffect(()=>{

    (async () => { 
      
     var url = `https://api.mudaris.org/api/getCoin`;
   
      const response = await axios.post(url,{user_id:sessionStorage.getItem('id')}, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },

      }).then((res=>{
        console.log(res.data.coin)
       setCoints(res.data.coin)
      })).catch(error => {
        console.log(error)
      });
    })();  

  },[]);

  return (
    <div>
      <div id='iconsDiv'>

            <div className='row my-3' >

                
                <div className='col-md-4'><b>{sessionStorage.getItem('name')}</b></div>
                <div className='col-md-4'><img src={Coin} alt='coins' style={{width:'35px',height:'35px'}}/> <b>{coins}</b></div>
               

            </div>
        

           

      </div>
    </div>
  )
}
