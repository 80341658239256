import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as ReactDOM from "react-dom/client";
import Login from './components/login'
import Courses from './components/courseList'
import LeaderBoard from './components/leaderboard'
import Profile from './components/profile'
import CourseDetail from './components/courseDetail'
import Lecture from './components/lecture'
import QuizPrompt from './components/QuizPrompt';
import Quiz from './components/Quiz';
import CompletionScreen from './components/completionScreen';
import Subscription from './components/subscription';
import Tutorials from './components/tutorials';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      
      {
        path: "/courses",
        element: <Courses />,
      },
      {
        path: "/tutorials",
        element: <Tutorials />,
      },
      {
        path: "/leaderboard",
        element: <LeaderBoard />,
      },
      {
        path: "/profile",
        element: <Profile />,
      },
      {
        path: "/courseDetail/:id",
        element: <CourseDetail />,
      },
      {
        path: "/lecture/:id/:course_id/:section_id/:completeStatus/:type",
        element: <Lecture />,
      },
      {
        path: "/QuizPrompt/:content_id/:course_id/:section_id",
        element: <QuizPrompt />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/home",
    element: <App />,
  },
  {
    path: "/Quiz/:id/:course_id/:section_id",
    element: <Quiz />,
  },
  {
    path: "CompletionScreen/:correct/:mistakes/:course_id/:content_id/:section_id",
    element: <CompletionScreen />
  },
  {
    path: "/Subscription",
    element: <Subscription />
  }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);


reportWebVitals();
