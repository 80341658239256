import React from 'react'
import BackBtn from '../images/back_arrow.png'
import Lecture from '../images/book.svg'
import Quiz from '../images/question.svg'
import { Link, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import axios  from 'axios'
import Loader from '../images/loader.gif'


export default function CourseDetail(props) {
    const { id } = useParams();

    const [sections,setSections] = useState([]);
    const [loaderDisplay, setLoaderDisplay] = useState("none");

    useEffect(()=>{

        const bearer_token = `Bearer ${sessionStorage.getItem("token")}`;
        const config = {
            headers: { Authorization: bearer_token }
        };
      
            (async () => { 
              setLoaderDisplay("block");
             var url = `https://api.mudaris.org/api/getSections/`+id;
           
              const response = await axios.get(url, {
                headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },

              }).then((res=>{

                setSections(res.data);
                setLoaderDisplay("none");
              })).catch(error => {
                console(error)
              });
            })();  
            
            console.log(sections );

    },[]);

  return (
    <div className='my-3'>
        <div id='backbtn'>
           <Link to='/courses'> <img src={BackBtn} alt='back btn' style={{width:"80px", height:"40px"}}/></Link>
        </div>

        
        <div className='my-3'>

        {sections.map((data)=>{

            return(
                <>
            <div  className='my-3'>
            
            <div style={{backgroundColor:data.section.bgcolor,padding:"10px",borderRadius:"10px",border:"2px solid"+ data.section.brcolor,color:data.section.color}}>
             <h3 style={{textAlign:"center"}}>{data.section.name}</h3>
             
            </div>
            <div className='content my-3'>
                <ul>

                    {data.content.map((content_data)=>{

                       if(content_data.type == "lecture"){
                        return (
                            
                            <>
                             <li>
                             {content_data.lockStatus != null ?
                                <Link to={`/lecture/${content_data.c_id}/${id}/${data.section.id}/${content_data.completeStatus}/${"course"}`}><div style={{backgroundColor:data.section.bgcolor,width:"100%",height:"auto",padding:"10px", border:"2px solid"+data.section.brcolor, borderRadius:"30px",boxShadow:"1px 4px "+data.section.brcolor,color:data.section.color}}><img src={Lecture} style={{width:"75px", height:"65px",}}/> {content_data.name} </div></Link>
                              :
                              <div style={{backgroundColor:data.section.bgcolor,width:"100%",height:"auto",padding:"10px", border:"2px solid"+data.section.brcolor, borderRadius:"30px",boxShadow:"1px 4px "+data.section.brcolor,color:data.section.color}}><img src={Lecture} style={{width:"75px", height:"65px",}}/> {content_data.name} <i className="bi bi-lock-fill"></i> </div>
                              }
                                </li>
                            </>

                        )
                       }else{
                        return (

                            <>
                                 <li>
                                 {content_data.lockStatus != null ?
                                <Link to={`/QuizPrompt/${content_data.c_id}/${id}/${data.section.id}`}><div style={{backgroundColor:data.section.bgcolor,width:"100%",height:"100px",padding:"10px", border:"2px solid"+data.section.brcolor, borderRadius:"50px",boxShadow:"1px 4px "+data.section.brcolor,color:data.section.color}}><img src={Quiz} style={{width:"75px", height:"65px",}}/>{content_data.name}</div></Link>
                              :
                             <div style={{backgroundColor:data.section.bgcolor,width:"100%",height:"100px",padding:"10px", border:"2px solid"+data.section.brcolor, borderRadius:"50px",boxShadow:"1px 4px "+data.section.brcolor,color:data.section.color}}><img src={Quiz} style={{width:"75px", height:"65px",}}/>{content_data.name}</div>
                              }
                   

                    </li>
                            </>

                        )
                       }


                    })}


                   
                   
                   
                </ul>
            </div>

        </div>
                </>

            )

            })}



       

     

        </div>

        <div style={{position:"fixed", top:"20%", left:"45%",display:loaderDisplay}} >
          <img  src={Loader} style={{width:"200px", height:"200px"}}/>
        </div>

    </div>
  )
}
