import React from 'react'
import Learn from '../images/learn.jpg';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from "axios";
import Loader from "../images/loader.gif"

export default function CourseList() {

    const [courses, setCourses] = useState([]);
    const [loaderDisplay, setLoaderDisplay] = useState("none");

    useEffect(()=>{
        const bearer_token = `Bearer ${sessionStorage.getItem("token")}`;
       
            (async () => { 
              setLoaderDisplay("block");
              const response = await axios.get(`https://api.mudaris.org/api/getEnrolledCourses/`+sessionStorage.getItem("id")+'/all', {
                headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },

              }).then((res=>{

               setCourses(res.data);
               setLoaderDisplay("none ");
              })).catch(error => {
                console.log(error)
              });
            })();
    },[]);
   

  return (
    <div>
        
        <div id='courseItem'>
        
                

            {courses.map((data)=>{
                return (
                    <>
                        <Link to={`/courseDetail/${data.courses.id}`} >
                        <div className='row my-5 courseList'  >
                        <div className='col-md-4'> 
                            <img src={data.courses.banner} style={{width:"100%", height:"150px", borderRadius:'10px'}}/>
                        </div>
                        <div className='col-md-8'> 
                            <h5>{data.courses.name}</h5>
                            <div style={{width:data.progress * 100+"%", height:"10px", backgroundColor:"red"}}></div>
                            <p>{Math.ceil(data.progress *100)} %</p>     
                            <hr/>
                        </div>
                        </div>
                        </Link>
                    </>
                )
            })


            }
                      
        </div>

        <div style={{position:"fixed", top:"20%", left:"45%",display:loaderDisplay}} >
          <img  src={Loader} style={{width:"200px", height:"200px"}}/>
        </div>
       
    </div>
  )
}
