import React from 'react'
import Quiz3 from  '../images/quiz_3.png'
import { useNavigate } from 'react-router-dom'

export default function Subscription() {
    const navigate = useNavigate();

    function handleLogin(){
        sessionStorage.clear()
        navigate("/login");
      }
  return (
    <div className='container'>

        <div className='row' style={{marginTop:"5%"}}>

            <div className='col-md-4'>
                <img src={Quiz3} width="250px" height="400px" />
            </div>
            <div className='col-md-8'>

                    <h2 style={{marginTop:"10%"}}>Downlaod <a style={{color:"blue"}} href='https://play.google.com/store/apps/details?id=com.developer.mtutor'> Mudaris APP </a> from PlayStore and become the premium member to access the Web.mudaris.org</h2>
                    <button className='btn btn-primary' onClick={handleLogin}>Logout</button>
            </div>

        </div>

    </div>
  )
}
